<template>
 
  <div class="page"> 
    
    <div class="avaf"> 
      <img class="ava" @click="avatarRandHandler" :src="'/avatars/' + avatar_num + '.jpg'">
      <div class="btn gray btn_rnd" @click="avatarRandHandler" type="is-primary"><i class="icon-shuffle"></i> {{ $t('signup_view_avatar_random') }}</div>
    </div>


    <UiInput
      :label="$t('signup_view_username')"
      :text="name"
      :name="'nick'"
      @change:text="name = $event"
      @keyup.enter="signUpHandler"
      />

    <UiInput
      :label="$t('signup_view_email')"
      :text="email"
      :name="'email'"
      @change:text="email = $event"
      @keyup.enter="signUpHandler"
      />

    <UiInput
      :label="$t('signup_view_password')"
      :text="password"
      type="password"
      :name="'password'"
      @change:text="password = $event"
      @keyup.enter="signUpHandler"
      />

    <UiInput
      :label="$t('signup_view_password2')"
      :text="password_repeat"
      type="password"
      :name="'password2'"
      @change:text="password_repeat = $event"
      @keyup.enter="signUpHandler"
      />
    
      <NuxtTurnstile  ref="turnstile" v-model="token" />
 
    <div class="acts"> 

      <UiButton
        :name="$t('universal_button_signup')"
        :type="'primary'"
        @click="signUpHandler"
        :is-loading="isLoading"
        />        

      <UiButton
        :name="$t('login_view_login_button')"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/auth/signin"
        />     

      <UiButton
        :name="$t('universal_button_cancel')"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/"
        />     
 

    </div>  
    
  </div>  
  
</template>





<script setup>

definePageMeta({
  auth: false,
  layout: "short",
})

const { t } = useI18n();
const router = useRouter();
const { $api, $refs, $ga, $popup } = useNuxtApp()
const { login, setSession, signIn } = useAuth()
const isLoading = ref(false)
const token = ref(null)
const turnstile = ref()
 
const name = ref('')
const email = ref(router.currentRoute?.value.query?.email || '')
const password = ref('')
const password_repeat = ref('')
const avatar_num = ref(Math.floor(Math.random() * 83) + 1)




const validate = function() {     
  
  if(!name.value || !email.value || !password.value || !password_repeat.value) {    
    $popup.error('Empty fields');
    return false;
  }

  var pattern = /^([a-zA-Z0-9_])+$/i;
  if(!pattern.test(name.value) || name.value.length < 5 || name.value.length > 20){ 
    $popup.error(t('signup_view_error_username_length'));
    return false;
  }

  if(email.value != '') {
    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,6}$/i;
    if(!pattern.test(email.value)){ 
      $popup.error(t('signup_view_error_email_format'));
      return false;
    }
  }else { 
    $popup.error(t('signup_view_error_email_format'));
    return false;
  }

  if(password.value.length < 5 || password_repeat.value.length < 5 ){
    $popup.error(t('signup_view_error_password_length'));
    return false;
  }

  if(password.value != '' && password_repeat.value != ''){
    if(password.value != password_repeat.value){
      $popup.error(t('signup_view_error_password_match'));
      return false;
    }
  }

  return true;
}


const avatarRandHandler = function() {
  avatar_num.value = Math.floor(Math.random() * 83) + 1;
}


const signUpHandler = async function() {

  if(!validate()) return false;

  // var recaptcha = await execute()

  isLoading.value = true;

  $api.postGrowers({ 
    name: name.value, 
    email: email.value, 
    password: password.value, 
    password_repeat: password_repeat.value, 
    avatar: avatar_num.value, 
    recaptcha: token.value
  })  
  .then(response => { 
    
    // $popup.success('Loading...');
    isLoading.value = false;  
    setSession(response.data.value.data.token);
    $popup.success('Success');
    router.push('/');

    $ga.addGrower();
 
  })
  .catch(error => {
    turnstile.value?.reset();
    isLoading.value = false;      
    $popup.error(error);
  });
  
  
}




</script>





 


<style scoped>

.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.ava{
  max-width: 180px;
  border-radius: 100%;
  display: block;
  cursor: pointer;
}
.avaf{
  margin-bottom: 1rem;
  text-align: center;
}
label{
  text-align: center;
}
.btn_rnd{
  display: block;
    z-index: 1;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-top: -28px;
}
.acts{
  display: flex;
  justify-content: center;
}
</style>
